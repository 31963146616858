@tailwind base;
@tailwind components;
@tailwind utilities;

.no-highlight {
  -webkit-tap-highlight-color: transparent;
}

.filepond--wrapper .filepond--root {
  margin-bottom: 0;
}

.filepond--wrapper .filepond--drop-label {
  @apply rounded-lg border border-black/30 bg-white text-sm text-black/50 transition-colors hover:border-black/40 hover:text-black/70;
}

.keep-scrolling {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.keep-scrolling::-webkit-scrollbar {
  display: none;
}
